"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { x: 0, style: {
                    fill: "#33547D",
                }, width: 200, height: 200 }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M52.7,84c8.8,0,16,7.2,16,16s-7.2,16-16,16s-16-7.2-16-16S43.9,84,52.7,84" }), (0, jsx_runtime_1.jsx)("circle", { style: {
                            fill: "#FFFFFF",
                        }, cx: 110.5, cy: 100.2, r: 11.3 }), (0, jsx_runtime_1.jsx)("circle", { style: {
                            fill: "#FFFFFF",
                        }, cx: 158.1, cy: 100.2, r: 5.1 })] })] })));
}
exports.default = Svg;
