"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#F05353", d: "M102.6,5c-1.8,0-3.5,0-5.3,0C74.7,18.6,52.1,32.1,29.5,45.7c-3.8,2.3-7.5,5-11.1,7.8v93 c21.4,13.1,43,26.1,64.3,39.2c5,3,9.8,6.3,14.6,9.3c1.8,0,3.5,0,5.3,0c26.4-15.8,53-31.7,78.9-47V52L102.6,5z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M160.8,92.8c0-10.3,0-18.1,0-28.4c-18.3-10.6-37.4-22.1-57.3-32.7c-2.8-1.5-8.3,0.5-11.6,2.5 c-14.3,7.8-28.4,16.1-42.2,24.6c-14.6,8.8-14.6,20.1,0,28.7c8.5,5,17.1,10.1,25.6,14.8c0,0,24.1,14.1,35.9,20.9 c2.8,1.5,5,3.8,8.5,6.5c-7.3,4.5-13.3,8-20.1,12.3c-20.4-11.8-39.7-22.9-60.6-34.9c0,10.3,0,18.1,0,28.4 c18.3,10.6,37.4,22.1,57.3,32.7c2.8,1.5,8.3-0.5,11.6-2.5c14.3-7.8,28.4-16.1,42.2-24.6c14.6-8.8,14.6-20.1,0-28.7 c-8.5-5-25.9-14.8-25.9-14.8c-11.8-7-23.6-14.1-35.4-20.9c-2.8-1.5-5-3.8-8.5-6.5c7.3-4.5,13.3-8,20.1-12.3 C120.4,69.5,139.7,80.8,160.8,92.8z" })] })));
}
exports.default = Svg;
