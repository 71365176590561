"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("rect", { fill: "#263746", width: 200, height: 200 }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("polygon", { opacity: 0.35, fill: "#FFFFFF", points: "31.2,77.1 31.2,122.9 77.1,122.9 77.1,168.8 122.9,168.8 122.9,77.1  " }), (0, jsx_runtime_1.jsx)("rect", { x: 31.2, y: 122.9, fill: "#FFFFFF", width: 45.9, height: 45.9 }), (0, jsx_runtime_1.jsx)("polygon", { opacity: 0.75, fill: "#FFFFFF", points: "122.9,77.1 122.9,31.2 77.1,31.2 77.1,77.1 77.1,122.9 122.9,122.9 168.8,122.9  168.8,77.1  " }), (0, jsx_runtime_1.jsx)("rect", { x: 122.9, y: 31.2, fill: "#FFFFFF", width: 45.9, height: 45.9 })] })] }) })));
}
exports.default = Svg;
