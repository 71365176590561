"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { fill: "#54349C", width: 200, height: 200 }), (0, jsx_runtime_1.jsxs)("linearGradient", __assign({ id: "SVGID_1_", gradientUnits: "userSpaceOnUse", x1: 90.0431, y1: 76.1707, x2: 104.4648, y2: 140.7253 }, { children: [(0, jsx_runtime_1.jsx)("stop", { offset: 0, style: {
                            stopColor: "#A18BE7",
                        } }), (0, jsx_runtime_1.jsx)("stop", { offset: 0.3893, style: {
                            stopColor: "#7D63C4",
                        } }), (0, jsx_runtime_1.jsx)("stop", { offset: 1, style: {
                            stopColor: "#54349C",
                        } })] })), (0, jsx_runtime_1.jsx)("path", { fill: "url(#SVGID_1_)", d: "M156.8,94.6c0.3-1.5,0.4-3,0.4-4.6c0-15-12.2-27.2-27.2-27.2c-5.8,0-11.1,1.8-15.5,4.9 c-6.4-9.4-17.3-15.6-29.5-15.6c-19.7,0-35.8,16-35.8,35.8c0,2,0.2,2.6,0.5,4.5c-14.6,0.5-26.2,13.7-26.2,28.4c0,14.3,11,26,25,27.1 v0.1h100.8c15,0,27.2-12.2,27.2-27.2C176.5,108.3,168.2,97.9,156.8,94.6z" }), (0, jsx_runtime_1.jsxs)("linearGradient", __assign({ id: "SVGID_2_", gradientUnits: "userSpaceOnUse", x1: 76.33, y1: 85.0256, x2: 123.67, y2: 132.3657 }, { children: [(0, jsx_runtime_1.jsx)("stop", { offset: 0, style: {
                            stopColor: "#CCCCCB",
                        } }), (0, jsx_runtime_1.jsx)("stop", { offset: 0.2191, style: {
                            stopColor: "#F6F6F9",
                        } }), (0, jsx_runtime_1.jsx)("stop", { offset: 0.8263, style: {
                            stopColor: "#F6F6F9",
                        } }), (0, jsx_runtime_1.jsx)("stop", { offset: 1, style: {
                            stopColor: "#CCCCCB",
                        } })] })), (0, jsx_runtime_1.jsx)("path", { fill: "url(#SVGID_2_)", d: "M144.9,107.7h-5.6V93.7c0-0.8-0.6-1.4-1.4-1.4h-30.6c-0.8,0-1.4,0.6-1.4,1.4v13.9h-5.1 c-0.1-0.1-0.1-0.2-0.2-0.3c-6.1-6.2-21.2-22-23.3-22c-2.1,0-23.2,22-23.2,22c-0.9,0.9-0.3,2.4,1,2.4h5.6v13.9c0,0.8,0.6,1.4,1.4,1.4 h30.6c0.8,0,1.4-0.6,1.4-1.4v-13.9h5.1c0.1,0.1,0.1,0.2,0.2,0.3c6.1,6.2,21.2,22,23.3,22c2.1,0,23.2-22,23.2-22 C146.7,109.2,146.1,107.7,144.9,107.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#DDDDDD", d: "M85.4,53.4c11.5,0,21.6,5.6,28,14.1c0.3,0.4,0.7,0.6,1.1,0.6c0.2,0,0.5-0.1,0.7-0.2c4.1-2.6,8.9-4,14-4 c14.6,0,26.4,11.8,26.4,26.4c0,1.1-0.1,2.3-0.2,3.4c-0.1,0.6,0.3,1.3,0.9,1.5c10.5,3.5,18.1,13.5,18.1,25.2 c-0.1,14.6-12.3,26.3-26.9,26.3H49.9l0,0c-13.7-1.4-24.3-13.1-24.3-27.1c0-13.9,10.5-25.4,24-26.9c0.7-0.1,1.3-0.7,1.2-1.5 c-0.1-0.9-0.1-1.9-0.1-2.9C50.6,69,66.2,53.4,85.4,53.4 M85.4,47.8c-22.1,0-40.1,17.8-40.3,39.9c-14.5,3.5-25,16.5-25,31.8 c0,8.1,3,16,8.5,22c4.3,4.7,9.8,8,15.9,9.6l4.3,0.8c0.9,0.2,1.8,0.3,2.6,0.3h3.5h92.7c17.9,0,32.4-14.3,32.5-31.9 c0.1-12.7-7.4-24.1-18.8-29.3c0-0.3,0-0.5,0-0.8c0-17.6-14.4-32-32-32c-4.8,0-9.4,1.1-13.7,3.1C107.9,52.8,96.9,47.8,85.4,47.8 L85.4,47.8z" })] })));
}
exports.default = Svg;
