"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#E01E5A", d: "M47.8,123.7c0,10.4-8.5,18.9-18.9,18.9S10,134.2,10,123.7c0-10.4,8.5-18.9,18.9-18.9h18.9V123.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#E01E5A", d: "M57.3,123.7c0-10.4,8.5-18.9,18.9-18.9s18.9,8.5,18.9,18.9v47.3c0,10.4-8.5,18.9-18.9,18.9 s-18.9-8.5-18.9-18.9C57.3,171.1,57.3,123.7,57.3,123.7z" })] }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#36C5F0", d: "M76.3,47.8c-10.4,0-18.9-8.5-18.9-18.9S65.8,10,76.3,10s18.9,8.5,18.9,18.9v18.9H76.3z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#36C5F0", d: "M76.3,57.3c10.4,0,18.9,8.5,18.9,18.9s-8.5,18.9-18.9,18.9H28.9C18.5,95.2,10,86.7,10,76.3 s8.5-18.9,18.9-18.9C28.9,57.3,76.3,57.3,76.3,57.3z" })] }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#2EB67D", d: "M152.2,76.3c0-10.4,8.5-18.9,18.9-18.9c10.4,0,18.9,8.5,18.9,18.9s-8.5,18.9-18.9,18.9h-18.9V76.3z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#2EB67D", d: "M142.7,76.3c0,10.4-8.5,18.9-18.9,18.9c-10.4,0-18.9-8.5-18.9-18.9V28.9c0-10.4,8.5-18.9,18.9-18.9 s18.9,8.5,18.9,18.9V76.3z" })] }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#ECB22E", d: "M123.7,152.2c10.4,0,18.9,8.5,18.9,18.9c0,10.4-8.5,18.9-18.9,18.9c-10.4,0-18.9-8.5-18.9-18.9v-18.9 H123.7z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#ECB22E", d: "M123.7,142.7c-10.4,0-18.9-8.5-18.9-18.9c0-10.4,8.5-18.9,18.9-18.9h47.3c10.4,0,18.9,8.5,18.9,18.9 s-8.5,18.9-18.9,18.9H123.7z" })] })] }) })));
}
exports.default = Svg;
