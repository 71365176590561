"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsx)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: (0, jsx_runtime_1.jsx)("path", { fill: "#3B474F", d: "M186.8,83.2c0-18.7-15.3-34-34-34c-16.1,0-21.2,6.4-38.2,8.5c-10.2,1.3-9.8-4.2-21.2-4.2 c-8.5,0-17,4.2-17,4.2s-8.1-9.8-12.7-8.5c-6.4,2.1,0,21.2,0,21.2S59.5,79,51,87.5c-6.4,6.4-21.2,9.8-28.9,11.9 c0.4-39,32.3-70.9,71.3-70.9c17,0,32.7,5.9,45,16.1c3-0.8,6.8-1.3,10.6-1.7c-14.4-14-34-22.9-56-22.9c-43.7,0-79.8,36.1-79.8,80.2 S49.3,180,93.4,180c38.6,0,70.9-27.2,78.1-63.7c-3.4,1.7-6.8,3.4-10.2,5.1c-8.9,28-34.4,48.8-65.4,50.1c4.2-7.2,14-20.8,26.7-33.1 C151.1,111.2,186.8,115.5,186.8,83.2z M99.8,79c-3.4,0-6.4-3-6.4-6.4s3-6.4,6.4-6.4c3.4,0,6.4,3,6.4,6.4S103.2,79,99.8,79z" }) })));
}
exports.default = Svg;
