"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", enableBackground: "new 0 0 200 200", xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { fill: "#0675C1", width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { fill: "#FFFFFF", d: "M182,166.5H18c-2.8,0-5-2.2-5-5v-123c0-2.8,2.2-5,5-5h39.7c1.5,0,2.8,0.6,3.8,1.7l6,7c1,1.1,2.4,1.8,3.8,1.7 L182,43c2.8,0,5,2.2,5,5v113.5C187,164.3,184.8,166.5,182,166.5z" }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { fill: "#0675C1", d: "M64.9,90.1l-4.3,5c-2.9-2.5-5.9-3.6-9.3-3.6c-3.7,0-6.2,1.6-6.2,4.6c0,2.9,1.6,4.1,8.5,6.3 c7.6,2.4,12.5,5.6,12.5,13.4c0,7.7-6.1,13.2-16.4,13.2c-7,0-12.1-2.5-15.6-5.9l4.7-5.1c2.9,2.7,6.3,4.3,10.7,4.3s7.3-2.1,7.3-5.8 c0-3.4-1.6-4.8-8.1-6.8c-9.2-2.9-12.8-6.5-12.8-12.9c0-7.2,6.2-11.9,14.8-11.9C56.9,84.8,61.2,86.7,64.9,90.1z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#0675C1", d: "M81.2,92.3v12h12.7v6.5H81.2V128h-9V85.8h24.5l-1,6.5H81.2z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#0675C1", d: "M118.4,92.8V128h-9V92.8H98.2v-7H130l-1,7H118.4z" }), (0, jsx_runtime_1.jsx)("path", { fill: "#0675C1", d: "M165,99.4c0,9.8-7,14.2-16.6,14.2h-4.8V128h-9V85.8h13.1C158.4,85.8,165,90.2,165,99.4z M155.7,99.4 c0-4.9-2.9-7.1-8.2-7.1h-4V107h4.1C152.6,107,155.7,105.1,155.7,99.4z" })] })] })));
}
exports.default = Svg;
