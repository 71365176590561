"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("style", __assign({ type: "text/css" }, { children: "\n\t.st0{fill:#00BF6F;}\n\t.st1{fill:#FFFFFF;}\n" })), (0, jsx_runtime_1.jsx)("rect", { y: -0.5, className: "st0", width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { id: "_Compound_Path_", className: "st1", d: "M161.08,107.9c-1.52,0.01-3.04,0.19-4.52,0.56c-5.63-22.12-23.65-38.92-46.11-42.97 c-1.01-0.18-1.91-0.32-2.94-0.45l0,0c0.17-5.5,0.42-11.86,8.52-17.58l-1.28-3.23c0,0-15.81,4.9-17.59,18.4 c-0.78-3.67-8.14-8.27-11.78-9.14l-1.81,2.92c0,0,4.83,2.41,6.01,9.07l0,0c-22.46,4.04-40.5,20.82-46.14,42.93 c-10.13-2.51-20.37,3.67-22.88,13.8c-2.51,10.13,3.67,20.37,13.8,22.88c3.81,0.94,7.81,0.68,11.46-0.76 c1.61,4.02,3.67,7.85,6.14,11.42l15.68-10.57l-0.14-0.17c-4.15-5.33-6.68-13.18-7.11-20.98c-0.47-8.62,1.63-17.17,7.13-22.2 c11.34-9.66,23.71-5.25,31.42,4h2.08c7.74-9.25,20.12-13.65,31.42-4c5.5,5.03,7.6,13.59,7.13,22.2c-0.42,7.81-2.96,15.65-7.11,20.98 l-0.14,0.17l15.68,10.57c2.47-3.56,4.53-7.39,6.15-11.42c9.7,3.83,20.67-0.92,24.51-10.62c3.83-9.7-0.92-20.67-10.62-24.51 C165.83,108.34,163.47,107.89,161.08,107.9z M38.57,132.32c-3.08,0-5.57-2.5-5.57-5.57c0-3.08,2.5-5.57,5.57-5.57l0,0 c1.11,0,2.2,0.34,3.13,0.96c0.05,2.93,0.25,5.85,0.6,8.76c-1.02,0.94-2.36,1.46-3.74,1.47L38.57,132.32z M161.42,132.32 c-1.38,0-2.7-0.52-3.72-1.44c0.35-2.91,0.55-5.83,0.6-8.76c2.54-1.73,6.01-1.06,7.73,1.48c0.63,0.93,0.96,2.02,0.96,3.14 c0.03,3.07-2.44,5.58-5.51,5.61c-0.02,0-0.03,0-0.05,0L161.42,132.32z" })] })));
}
exports.default = Svg;
