"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { x: 0, style: {
                    fill: "#1C63C1",
                }, width: 200, height: 200 }), (0, jsx_runtime_1.jsx)("path", { id: "Stripe_Logo_1_", style: {
                    fill: "#FFFFFF",
                }, d: "M153.4,98c0.3-4.6,1.5-6.8,3.9-6.8c2.3,0,3.6,2.2,3.7,6.8H153.4z M170.8,100.8 c0-5.2-1.1-9.3-3.3-12.2c-2.3-2.9-5.6-4.4-10-4.4c-8.8,0-14.3,6.5-14.3,17c0,5.9,1.5,10.2,4.3,13c2.6,2.5,6.3,3.8,11.1,3.8 c4.4,0,8.5-1,11.1-2.8l-1.1-7.1c-2.6,1.4-5.5,2.1-8.9,2.1c-2,0-3.4-0.4-4.4-1.3c-1.1-0.9-1.7-2.4-1.9-4.6h17.2 C170.8,103.9,170.8,101.5,170.8,100.8z M129.4,107.8c-0.8,1.6-2.1,2.4-3.6,2.4c-1,0-2-0.2-2.8-0.6V94.1c1.8-1.8,3.3-2,3.9-2 c2.6,0,3.9,2.8,3.9,8.4C130.8,103.7,130.3,106.1,129.4,107.8z M138,87.7c-1.8-2.4-4.4-3.6-7.7-3.6c-3,0-5.6,1.3-8.1,3.9l-0.5-3.3 h-9.1v44.6l10.4-1.7v-10.5c1.6,0.5,3.2,0.8,4.7,0.8c2.6,0,6.4-0.7,9.3-3.8c2.8-3.1,4.2-7.8,4.2-14C141.1,94.6,140.1,90.4,138,87.7z  M98,84.8h10.4v32.5H98V84.8z M103.3,81.6c3,0,5.4-2.5,5.4-5.5c0-3.1-2.4-5.5-5.4-5.5c-3.1,0-5.5,2.4-5.5,5.5 C97.8,79.1,100.2,81.6,103.3,81.6z M93.6,84.3c-2.9,0-5.3,1.5-6.2,4.3l-0.6-3.8h-9.1v32.5H88V96.2c1.3-1.6,3.1-2.2,5.6-2.2 c0.5,0,1.1,0,1.8,0.1v-9.6C94.8,84.4,94.2,84.3,93.6,84.3z M73.7,92.5l1.3-7.7h-6.6v-9.3l-8.9,1.5l-1.3,7.9L55,85.3l-1.2,7.2h4.3 v15.1c0,3.9,1,6.6,3.1,8.3c1.7,1.4,4.2,2,7.7,2c2.7,0,4.3-0.5,5.4-0.8v-8.2c-0.6,0.2-2,0.5-3,0.5c-2,0-2.9-1-2.9-3.4V92.5H73.7z  M44,97.1c-3.1-1.1-4.7-2-4.7-3.4c0-1.2,1-1.8,2.7-1.8c3.1,0,6.4,1.2,8.6,2.3l1.3-7.7c-1.8-0.8-5.4-2.2-10.3-2.2 c-3.5,0-6.4,0.9-8.5,2.6c-2.2,1.8-3.3,4.4-3.3,7.5c0,5.7,3.5,8.1,9.1,10.2c3.6,1.3,4.9,2.2,4.9,3.6c0,1.4-1.2,2.2-3.3,2.2 c-2.6,0-7-1.3-9.8-3l-1.3,7.8c2.4,1.4,6.9,2.8,11.6,2.8c3.7,0,6.8-0.9,8.9-2.6c2.3-1.8,3.6-4.6,3.6-8.1 C53.2,101.5,49.7,99.1,44,97.1L44,97.1z" })] })));
}
exports.default = Svg;
