"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ id: "Layer_1", xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", xmlnsXlink: "http://www.w3.org/1999/xlink", x: "0px", y: "0px", viewBox: "0 0 200 200", style: {
            enableBackground: "new 0 0 200 200",
        }, xmlSpace: "preserve" }, props, { children: [(0, jsx_runtime_1.jsx)("rect", { x: 0, style: {
                    fill: "#723080",
                }, width: 200, height: 200 }), (0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsxs)("g", { children: [(0, jsx_runtime_1.jsx)("path", { style: {
                                    fill: "#FFFFFF",
                                }, d: "M45,102.8c-1.7-2.6-2.9-4.3-3.8-5c4.4-1.1,8.7-4.8,8.7-10.8c0-7.3-5-10.8-13-10.8h0l0,0 c-7,0-11.1,3.3-12,9.1h-3.5l7,5.9l7.4-5.9h-4c0,0,0-4.1,5.2-4.1c4.4,0,6,3.2,6,5.9c0,3.9-2.1,6.9-7,6.9h-11l0,19.1h7V98.7h0 c1.5,0,3,0.6,5.6,4.4l6.2,10h7.8L45,102.8z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                                    fill: "#FFFFFF",
                                }, d: "M36.7,76.2l0.2,0v0C36.9,76.2,36.7,76.2,36.7,76.2z" }), (0, jsx_runtime_1.jsx)("line", { style: {
                                    fill: "#FFFFFF",
                                }, x1: 36.7, y1: 76.2, x2: 36.7, y2: 76.2 })] }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M144.3,113.1V78.3h6.5v34.8H144.3z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M64.3,108.7c3.2,0,5-1,6.2-1.6l2.1,4.1c-1.6,0.9-4,2.3-8.9,2.3c-7.9,0-12.4-5-12.4-12.6 c0-7.5,5.2-12.3,11.7-12.3c7.4,0,11,5.3,10.2,14h-15C58.5,106.3,60.4,108.7,64.3,108.7z M67,98.2c0-2.7-1.1-4.8-4.1-4.8 c-2.7,0-4.3,1.7-4.7,4.8H67z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M88.4,113.5c-7.6,0-12.3-5.1-12.3-12.4c0-8,5.3-12.5,12.5-12.5c3.8,0,6.1,1.2,7.2,1.9l-2.1,4.2 c-1.1-0.7-2.6-1.4-5-1.4c-3.8,0-5.8,3.2-5.8,7.6s1.9,7.8,5.9,7.8c2.7,0,4.2-1.1,4.9-1.6l2,4.2C94.8,112,92.8,113.5,88.4,113.5z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M105.9,89.4V104c0,2.4,0.8,3.6,3.2,3.6c2.5,0,4.4-2.4,4.9-3V89.4h6.5v16.9c0,3,0.3,5.4,0.6,6.8h-6.4 c-0.2-0.6-0.4-2.2-0.5-3.2c-1.3,1.6-3.7,3.7-7.6,3.7c-5.2,0-7.1-3.4-7.1-7.7V89.4H105.9z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M139.2,95.1c-0.4-0.2-1.1-0.5-2.5-0.5c-2.9,0-4.6,2.5-5.2,3.6v14.9h-6.5V89.4h6.5v3.2 c1-1.8,3-3.8,6-3.8c1.8,0,2.7,0.3,3.2,0.6L139.2,95.1z" }), (0, jsx_runtime_1.jsx)("path", { style: {
                            fill: "#FFFFFF",
                        }, d: "M171.4,89.4l-4.6,15.6l-5.5-15.6h-7.1l9.1,21.8c-1.5,6-3,7.7-6.4,7.7c-1.5,0-2.3-0.2-2.6-0.3 l-0.9,4.6c0.5,0.2,1.9,0.6,3.9,0.6c6.7,0,9.6-4.4,11.7-10.1l7.3-18.3l2.3-6L171.4,89.4z" })] })] })));
}
exports.default = Svg;
