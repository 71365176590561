"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
function Svg(props) {
    return ((0, jsx_runtime_1.jsxs)("svg", __assign({ xmlns: "http://www.w3.org/2000/svg", height: "100%", width: "100%", viewBox: "0 0 200 200" }, props, { children: [(0, jsx_runtime_1.jsx)("defs", { children: (0, jsx_runtime_1.jsx)("style", { children: ".cls-1{fill:#e72964;}" }) }), (0, jsx_runtime_1.jsx)("g", __assign({ id: "Guidelines" }, { children: (0, jsx_runtime_1.jsx)("path", { className: "cls-1", d: "M100,169.87V155.4c0-17.31-.06-34.62,0-51.94,0-2.8-.87-3.55-3.6-3.53-20.91.1-41.82,0-62.74,0H30a20.33,20.33,0,0,1,1.51-1.93Q64.77,64.86,98.09,31.73a5.43,5.43,0,0,1,3.41-1.53c22.06-.09,44.12-.07,66.19-.06.65,0,1.3.09,2.31.16v3.18c0,21.33,0,42.66-.07,64a6.53,6.53,0,0,1-1.58,4.24q-33.25,33.41-66.68,66.62C101.28,168.71,100.86,169.05,100,169.87Z" }) }))] })));
}
exports.default = Svg;
